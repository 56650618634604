import axios from 'axios'
import { baseURL } from '../../../service/API'

export const LOGIN_URL = `${baseURL}/login`

export function login(email, password) {
  localStorage.setItem("email", email)
  localStorage.setItem("pass", password)
  return axios.post(LOGIN_URL, { email, password })
}

export function getUserByToken() {
  // Authorization head should be fulfilled in interceptor.
  let email = localStorage.getItem('email')
  let password = localStorage.getItem('pass')
  return axios.post(LOGIN_URL, { email, password })
}
