import React, { useState, useEffect } from 'react'
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar
} from '../../_metronic/_partials/controls'
import BootstrapTable from 'react-bootstrap-table-next'
import {
  NoRecordsFoundMessage,
  PleaseWaitMessage,
  sortCaret,
  headerSortingClasses
} from '../../_metronic/_helpers'
import paginationFactory from 'react-bootstrap-table2-paginator'
import { useSubheader } from '../../_metronic/layout'
import axios from 'axios'
import { Link } from 'react-router-dom'
import { useHistory } from 'react-router-dom'
import moment from 'moment'
import Swal from 'sweetalert2'
import { baseURL, baseURLDASHBOARD } from '../service/API.js'
import { Formik, Form, Field, ErrorMessage } from 'formik'
import Modal from '@material-ui/core/Modal'
import { TransformWrapper, TransformComponent } from 'react-zoom-pan-pinch'
import Pagination from 'react-pagination-library'
import 'react-pagination-library/build/css/index.css'
import { useSelector } from 'react-redux'
export const Transaction = () => {
  const { user } = useSelector(state => state.auth)
  const suhbeader = useSubheader()
  const allData = []
  const [transaction, setTransaction] = useState([])
  const [approve, setApprove] = useState(false)
  const [reject, setReject] = useState(false)
  const [id, setId] = useState('')
  const [total, setTotal] = useState(0)
  const [perPage, setPerPage] = useState(10)
  const [lastPage, setLastPage] = useState(0)
  const [page, setPage] = useState(1)
  const [openImage, setOpenImage] = useState(false)
  const [imgSelect, setImgSelect] = useState('')
  const [currentPage, setCurrentPage] = useState()
  suhbeader.setTitle('Transaction')
  const history = useHistory()

  useEffect(() => {
    getTransaction(1, 10)
  }, [])

  const getTransaction = async (page, perPage) => {
    console.log('page', page)
    console.log('perPage', perPage)
    console.log('user.data.access_token', user.data.access_token)

    const apiUrls = `${baseURL}/histories?per_page=${perPage}&page=${page}&type=1${
      search !== undefined && search !== '' ? '&q=' + search : ''
    }`
    const resultAll = axios({
      method: 'get',
      url: apiUrls,
      headers: {
        Accept: 'application/json',
        Authorization: `Bearer ${user.data.access_token}`
      }
    })
      .then(async res => {
        console.log(res.data.data)
        await res.data.data.map(async (data, index) => {
          data = {
            id: data.id,
            first_name: data.first_name ? data.first_name : ' ',
            last_name: data.last_name ? data.last_name : ' ',
            mobile: data.phone_number ? data.phone_number : ' ',
            slip: data.code,
            created_at: moment(data.created_at).format('YYYY-MM-DD HH:mm:ss'),
            rank: data.rank,
            img: data.media ? data.media.urls : null,
            status: data.status,
            reject_note: data.reject_note,
            point: data.point,
          }

          allData.push(data)
        })
        await setTotal(res.data.total)
        await setLastPage(res.data.meta.last_page)
        await setTransaction(allData)
      })
      .catch(err => {
        console.log(err)
      })
  }

  const getTransactionPage = async p => {
    await getTransaction(p, perPage)
  }

  const getTransactionPerPage = async p => {
    await setPerPage(p)
    await getTransaction(page, p)
  }

  const setOpen = (name, id) => {
    console.log(name.target.value)
    if (name.target.value === 'Approve') {
      setApprove(true)
      setId(id)
    } else {
      setReject(true)
      setId(id)
    }
  }

  const columns = [
    {
      dataField: 'id',
      text: 'ID',
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses
    },
    {
      dataField: 'first_name',
      text: 'First name',
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses
    },
    {
      dataField: 'last_name',
      text: 'Last name',
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses
    },
    {
      dataField: 'mobile',
      text: 'Mobile',
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses
    },
    {
      dataField: 'slip',
      text: 'Code',
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses
    },
    {
      dataField: 'created_at',
      text: 'Earned Date',
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses
    },
    {
      dataField: 'rank',
      text: 'Rank',
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses
    },
  ]

  const handleClose = () => {
    setApprove(false)
    setReject(false)
    setId('')
    setOpenImage(false)
    setImgSelect('')
  }

  const handleSubmitApprove = async (values, { setSubmitting }) => {
    await setSubmitting(false)
    await setActives(values)
    return
  }
  const handleSubmitReject = async (values, { setSubmitting }) => {
    await setSubmitting(false)
    await setRejects(values)
    return
  }

  const setActives = async values => {
    const apiUrls = `${baseURL}/transactions/${id}`
    axios({
      method: 'patch',
      url: apiUrls,
      headers: {
        Accept: 'application/json',
        Authorization: `Bearer ${user.data.access_token}`
      },
      data: {
        quantity: Number(values.quantity)
      }
    })
      .then(res => {
        axios({
          method: 'patch',
          url: `${baseURL}/transactions/${id}/status`,
          headers: {
            Accept: 'application/json',
            Authorization: `Bearer ${user.data.access_token}`
          },
          data: {
            status: 2
          }
        })
          .then(res => {
            getTransaction(page, perPage)
            handleClose()
          })
          .catch(err => {
            console.log(err)
          })
      })
      .catch(err => {
        console.log(err)
      })
  }

  const setRejects = async values => {
    axios({
      method: 'patch',
      url: `${baseURL}/transactions/${id}/status`,
      headers: {
        Accept: 'application/json',
        Authorization: `Bearer ${user.data.access_token}`
      },
      data: {
        status: 3,
        reject_note: values.reject_note
      }
    })
      .then(res => {
        getTransaction(page, perPage)
        handleClose()
      })
      .catch(err => {
        console.log(err)
      })
  }

  const [search, setSearch] = useState()
  const handleSearch = () => {
    getTransaction(page, perPage)
  }

  const onChanges = e => {
    setPerPage(e.target.value)
    getTransactionPerPage(e.target.value)
  }

  const pagination = paginationFactory({
    page: lastPage,
    alwaysShowAllBtns: true,
    withFirstAndLast: false,
    pageListRenderer: ({ pages, onPageChange }) => {
      return (
        <div className="dataTables_length" id="datatable-basic_length">
          <label>
            <Pagination
              currentPage={currentPage}
              totalPages={lastPage}
              changeCurrentPage={async numPage => {
                onPageChange(numPage)
                await setPage(numPage)
                await setCurrentPage(numPage)
                await getTransactionPage(numPage)
              }}
              theme="bottom-border form form-control-sm"
            />
          </label>
        </div>
      )
    },
    sizePerPageRenderer: ({
      options,
      currSizePerPage,
      onSizePerPageChange
    }) => (
      <div className="dataTables_length" id="datatable-basic_length">
        <label>
          {
            <select
              name="datatable-basic_length"
              aria-controls="datatable-basic"
              className="form-control form-control-sm"
              onChange={e => {
                onChanges(e)
                onSizePerPageChange(e.target.value)
              }}
            >
              <option value="10">10</option>
              <option value="25">25</option>
              <option value="50">50</option>
              <option value="100">100</option>
            </select>
          }
        </label>
      </div>
    )
  })

  return (
    <Card>
      <CardHeader title="Transaction"></CardHeader>
      <CardBody>
        <div className="row form-inline mb-2">
          <div className="col-lg-4 col-md-4 col-sm-4">
            <div className="form-group">
              <input
                type="text"
                className="form-control"
                placeholder="Text Search"
                style={{ width: '100%' }}
                onChange={e => setSearch(e.target.value)}
              />
            </div>
          </div>
          <div className="col-lg-2 col-md-2 col-sm-2">
            <div className="form-group">
              <button
                type="button"
                id="button"
                className="btn btn-primary"
                style={{ width: '100%' }}
                onClick={() => handleSearch()}
              >
                Search
              </button>
            </div>
          </div>
        </div>
        <BootstrapTable
          wrapperClasses="table-responsive"
          bordered={false}
          classes="table table-head-custom table-vertical-center overflow-hidden"
          bootstrap4
          keyField="id"
          pagination={pagination}
          data={transaction === undefined ? [] : transaction}
          columns={columns}
        >
          <PleaseWaitMessage entities={transaction} />
          <NoRecordsFoundMessage entities={transaction} />
        </BootstrapTable>
      </CardBody>

      <Modal
        open={openImage}
        disablePortal
        disableEnforceFocus
        disableAutoFocus
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <TransformWrapper
              defaultScale={1}
              defaultPositionX={200}
              defaultPositionY={100}
            >
              {({ zoomIn, zoomOut, resetTransform, ...rest }) => (
                <React.Fragment>
                  <TransformComponent>
                    <div className="modal-body">
                      <img
                        style={{
                          width: '100%',
                          height: '100%',
                          maxWidth: '500px',
                          maxHeight: '500px'
                        }}
                        src={imgSelect}
                      />
                    </div>
                  </TransformComponent>
                  <div className="modal-footer">
                    <div className="tools">
                      <button
                        type="button"
                        className="btn btn-primary mr-1"
                        onClick={zoomIn}
                      >
                        +
                      </button>
                      <button
                        type="button"
                        className="btn btn-primary mr-1"
                        onClick={zoomOut}
                      >
                        -
                      </button>
                    </div>
                    <button
                      type="button"
                      className="btn btn-secondary"
                      onClick={() => handleClose()}
                    >
                      Close
                    </button>
                  </div>
                </React.Fragment>
              )}
            </TransformWrapper>
          </div>
        </div>
      </Modal>

      <Modal open={approve} disablePortal disableEnforceFocus disableAutoFocus>
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="justify-content-center d-flex align-items-center mt-1">
              <span style={{ fontSize: '30px' }}>Approve</span>
            </div>
            <div className="modal-body">
              <Formik
                initialValues={{
                  quantity: ''
                }}
                validate={values => {
                  let errors = {}

                  if (!values.quantity)
                    errors.quantity = 'Quantity is required.'

                  return errors
                }}
                onSubmit={handleSubmitApprove}
                render={formProps => {
                  return (
                    <Form className="col">
                      <div className="row">
                        <span
                          style={{
                            fontSize: '20px',
                            float: 'left'
                          }}
                        >
                          Quantity :
                        </span>

                        <Field
                          name="quantity"
                          className="form-control"
                          placeholder="Quantity"
                          style={{
                            height: '38px',
                            width: '530px',
                            backgroundColor: '#F4F6F9',
                            borderRadius: '5px',
                            borderWidth: '0'
                          }}
                        />
                      </div>
                      <div className="form-inline">
                        <ErrorMessage className="colorError" name="quantity">
                          {msg => <div style={{ color: 'red' }}>{msg}</div>}
                        </ErrorMessage>
                      </div>

                      <div className="modal-footer">
                        <button
                          className="btn btn-primary"
                          type="submit"
                          disabled={formProps.isSubmitting}
                        >
                          Approve
                        </button>
                        <button
                          type="button"
                          className="btn btn-secondary"
                          onClick={() => handleClose()}
                        >
                          Close
                        </button>
                      </div>
                    </Form>
                  )
                }}
              />
            </div>
          </div>
        </div>
      </Modal>

      <Modal open={reject} disablePortal disableEnforceFocus disableAutoFocus>
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="justify-content-center d-flex align-items-center mt-1">
              <span style={{ fontSize: '30px' }}>Reject</span>
            </div>
            <div className="modal-body">
              <Formik
                initialValues={{
                  reject_note: ''
                }}
                validate={values => {
                  let errors = {}

                  if (!values.reject_note)
                    errors.reject_note = 'Reject note is required.'

                  return errors
                }}
                onSubmit={handleSubmitReject}
                render={formProps => {
                  return (
                    <Form className="col">
                      <div className="row">
                        <span
                          style={{
                            fontSize: '20px',
                            float: 'left'
                          }}
                        >
                          Reject note :
                        </span>

                        <Field
                          name="reject_note"
                          className="form-control"
                          placeholder="Reject note"
                          style={{
                            height: '38px',
                            width: '530px',
                            backgroundColor: '#F4F6F9',
                            borderRadius: '5px',
                            borderWidth: '0'
                          }}
                        />
                      </div>
                      <div className="form-inline">
                        <ErrorMessage className="colorError" name="reject_note">
                          {msg => <div style={{ color: 'red' }}>{msg}</div>}
                        </ErrorMessage>
                      </div>

                      <div className="modal-footer">
                        <button
                          className="btn btn-primary"
                          type="submit"
                          disabled={formProps.isSubmitting}
                        >
                          Reject
                        </button>
                        <button
                          type="button"
                          className="btn btn-secondary"
                          onClick={() => handleClose()}
                        >
                          Close
                        </button>
                      </div>
                    </Form>
                  )
                }}
              />
            </div>
          </div>
        </div>
      </Modal>
    </Card>
  )
}
