import React, { useState, useEffect, useRef } from 'react'
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar
} from '../../_metronic/_partials/controls'
import BootstrapTable from 'react-bootstrap-table-next'
import {
  NoRecordsFoundMessage,
  PleaseWaitMessage,
  sortCaret,
  headerSortingClasses
} from '../../_metronic/_helpers'
import paginationFactory from 'react-bootstrap-table2-paginator'
import { useSubheader } from '../../_metronic/layout'
import axios from 'axios'
import { Link } from 'react-router-dom'
import { useHistory } from 'react-router-dom'
import moment from 'moment'
import Swal from 'sweetalert2'
import { baseURL, baseURLDASHBOARD } from '../service/API.js'
import { Formik, Form, Field, ErrorMessage } from 'formik'
import Modal from '@material-ui/core/Modal'
import { TransformWrapper, TransformComponent } from 'react-zoom-pan-pinch'
import Pagination from 'react-pagination-library'
import 'react-pagination-library/build/css/index.css'
import { useSelector } from 'react-redux'
import { useReactToPrint } from 'react-to-print'
export const Redemption = () => {
  const { user } = useSelector(state => state.auth)
  const suhbeader = useSubheader()
  const allData = []
  const [redemption, setRedemption] = useState([])
  const [openDetailModalData, setOpenDetailModalData] = useState([])
  const [approve, setApprove] = useState(false)
  const [reject, setReject] = useState(false)
  const [id, setId] = useState('')
  const [total, setTotal] = useState(0)
  const [perPage, setPerPage] = useState(10)
  const [lastPage, setLastPage] = useState(0)
  const [page, setPage] = useState(1)
  const [openImage, setOpenImage] = useState(false)
  const [imgSelect, setImgSelect] = useState('')
  const [currentPage, setCurrentPage] = useState()
  const [openDetailModal, setOpenDetailModal] = useState(false)

  const componentRef = useRef()
  const handlePrint = useReactToPrint({
    content: () => componentRef.current
  })

  suhbeader.setTitle('Redemption')
  const history = useHistory()

  useEffect(() => {
    getRedemption(1, 10)
  }, [])

  const getRedemption = async (page, perPage) => {
    const apiUrls = `${baseURL}/histories?per_page=${perPage}&page=${page}&type=0${
      search !== undefined && search !== '' ? '&q=' + search : ''
    }`
    const resultAll = axios({
      method: 'get',
      url: apiUrls,
      headers: {
        Accept: 'application/json',
        Authorization: `Bearer ${user.data.access_token}`
      }
    })
      .then(async res => {
        console.log('res.data.data', res.data.data)

        await res.data.data.map(async (data, index) => {
          data = {
            id: data.id,
            first_name: data.first_name ? data.first_name : ' ',
            last_name: data.last_name ? data.last_name : ' ',
            full_name:
              (data.first_name ? data.first_name : ' ') +
              ' ' +
              (data.last_name ? data.last_name : ' '),
            mobile: data.phone_number ? data.phone_number : ' ',
            slip: data.code,
            delivery_id: data.delivery.id,
            delivery_status: data.delivery.delivery_status,
            delivery: data.delivery
              ? data.delivery.address +
                ' ' +
                data.delivery.address_detail +
                ' ' +
                data.delivery.district +
                ' ' +
                data.delivery.sub_district +
                ' ' +
                data.delivery.province +
                ' ' +
                data.delivery.zip_code
              : null,
            created_at: data.created_at,
            img: data.media ? data.media.urls : null,
            status: data.status,
            reject_note: data.reject_note,
            point: data.point,
            privilege_name: data.privilege_name,
            privilege_url: data.privilege_url
          }

          allData.push(data)
        })
        await setTotal(res.data.total)
        await setLastPage(res.data.meta.last_page)
        await setRedemption(allData)
      })
      .catch(err => {
        console.log(err)
      })
  }

  const getRedemptionPage = async p => {
    await getRedemption(p, perPage)
  }

  const getRedemptionPerPage = async p => {
    await setPerPage(p)
    await getRedemption(page, p)
  }

  const setOpen = (name, row) => {
    console.log(row.delivery_id)
    console.log(row)
    setReject(true)
    setId(row.delivery_id)
  }

  const setOpenDetail = (name, id, row) => {
    console.log(row)
    setOpenDetailModal(true)
    setOpenDetailModalData(row)
  }

  const columns = [
    {
      text: 'Privilege',
      dataField: 'id',
      formatter: (id, row, rowIndex) => {
        return (
          <div>
            {row.privilege_url !== '' ? (
              <a
                onClick={() => {
                  setOpenImage(true)
                  setImgSelect(row.privilege_url)
                }}
              >
                <img
                  style={{
                    width: '200px',
                    height: '100px'
                  }}
                  src={row.privilege_url}
                />
              </a>
            ) : (
              ''
            )}
          </div>
        )
      }
    },
    {
      dataField: 'privilege_name',
      text: 'Privilege Name',
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses
    },
    {
      dataField: 'first_name',
      text: 'First name',
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses
    },
    {
      dataField: 'last_name',
      text: 'Last name',
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses
    },
    {
      dataField: 'mobile',
      text: 'Mobile',
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses
    },
    {
      dataField: 'created_at',
      text: 'Redeemed Date',
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses
    },
    {
      text: 'Shipping',
      dataField: 'delivery_status',
      formatter: (id, row, rowIndex) => {
        return (
          <div>
            {row.delivery_status === '3' ? null : (
              <select
                as="select"
                className="dropdown"
                defaultValue={0}
                onChange={name => setOpen(name, row)}
              >
                <option disabled value={0}>
                  {' '}
                  เปลี่ยนสถานะ
                </option>
                <option value={'3'}>จัดส่งแล้ว</option>
              </select>
            )}
          </div>
        )
      }
    },
    {
      text: 'Detail',
      dataField: 'status',
      formatter: (id, row, rowIndex) => {
        return (
          <div>
            <a onClick={name => setOpenDetail(name, row.id, row)}>
              <svg
                width="1em"
                height="1em"
                viewBox="0 0 16 16"
                class="bi bi-eye-fill"
                fill="currentColor"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M10.5 8a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0z" />
                <path
                  fill-rule="evenodd"
                  d="M0 8s3-5.5 8-5.5S16 8 16 8s-3 5.5-8 5.5S0 8 0 8zm8 3.5a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7z"
                />
              </svg>
            </a>
          </div>
        )
      }
    }
  ]

  const handleClose = () => {
    setApprove(false)
    setReject(false)
    setId('')
    setOpenImage(false)
    setOpenDetailModal(false)
    setImgSelect('')
    setOpenDetailModalData([])
  }

  const print = () => {}

  const handleSubmitApprove = async (values, { setSubmitting }) => {
    await setSubmitting(false)
    await setActives(values)
    return
  }
  const handleSubmitStatus = async (values, { setSubmitting }) => {
    console.log('update status')
    await setSubmitting(false)
    await setStatusValue()
  }

  const setActives = async values => {
    const apiUrls = `${baseURL}/transactions/${id}`
    axios({
      method: 'patch',
      url: apiUrls,
      headers: {
        Accept: 'application/json',
        Authorization: `Bearer ${user.data.access_token}`
      },
      data: {
        quantity: Number(values.quantity)
      }
    })
      .then(res => {
        axios({
          method: 'patch',
          url: `${baseURL}/transactions/${id}/status`,
          headers: {
            Accept: 'application/json',
            Authorization: `Bearer ${user.data.access_token}`
          },
          data: {
            status: 2
          }
        })
          .then(res => {
            getRedemption(page, perPage)
            handleClose()
          })
          .catch(err => {
            console.log(err)
          })
      })
      .catch(err => {
        console.log(err)
      })
  }

  const setStatusValue = async values => {
    axios({
      method: 'patch',
      url: `${baseURL}/deliveries/${id}/status`,
      headers: {
        Accept: 'application/json',
        Authorization: `Bearer ${user.data.access_token}`
      },
      data: {
        status: 3
      }
    })
      .then(res => {
        getRedemption(page, perPage)
        handleClose()
      })
      .catch(err => {
        console.log(err)
      })
  }

  const [search, setSearch] = useState()
  const handleSearch = () => {
    getRedemption(page, perPage)
  }

  const onChanges = e => {
    setPerPage(e.target.value)
    getRedemptionPerPage(e.target.value)
  }

  const pagination = paginationFactory({
    page: lastPage,
    alwaysShowAllBtns: true,
    withFirstAndLast: false,
    pageListRenderer: ({ pages, onPageChange }) => {
      return (
        <div className="dataTables_length" id="datatable-basic_length">
          <label>
            <Pagination
              currentPage={currentPage}
              totalPages={lastPage}
              changeCurrentPage={async numPage => {
                onPageChange(numPage)
                await setPage(numPage)
                await setCurrentPage(numPage)
                await getRedemptionPage(numPage)
              }}
              theme="bottom-border form form-control-sm"
            />
          </label>
        </div>
      )
    },
    sizePerPageRenderer: ({
      options,
      currSizePerPage,
      onSizePerPageChange
    }) => (
      <div className="dataTables_length" id="datatable-basic_length">
        <label>
          {
            <select
              name="datatable-basic_length"
              aria-controls="datatable-basic"
              className="form-control form-control-sm"
              onChange={e => {
                onChanges(e)
                onSizePerPageChange(e.target.value)
              }}
            >
              <option value="10">10</option>
              <option value="25">25</option>
              <option value="50">50</option>
              <option value="100">100</option>
            </select>
          }
        </label>
      </div>
    )
  })

  return (
    <Card>
      <CardHeader title="Redemption"></CardHeader>
      <CardBody>
        <div className="row form-inline mb-2">
          <div className="col-lg-4 col-md-4 col-sm-4">
            <div className="form-group">
              <input
                type="text"
                className="form-control"
                placeholder="Text Search"
                style={{ width: '100%' }}
                onChange={e => setSearch(e.target.value)}
              />
            </div>
          </div>
          <div className="col-lg-2 col-md-2 col-sm-2">
            <div className="form-group">
              <button
                type="button"
                id="button"
                className="btn btn-primary"
                style={{ width: '100%' }}
                onClick={() => handleSearch()}
              >
                Search
              </button>
            </div>
          </div>
        </div>
        <BootstrapTable
          wrapperClasses="table-responsive"
          bordered={false}
          classes="table table-head-custom table-vertical-center overflow-hidden"
          bootstrap4
          keyField="id"
          pagination={pagination}
          data={redemption === undefined ? [] : redemption}
          columns={columns}
        >
          <PleaseWaitMessage entities={redemption} />
          <NoRecordsFoundMessage entities={redemption} />
        </BootstrapTable>
      </CardBody>

      <Modal
        open={openDetailModal}
        disablePortal
        disableEnforceFocus
        disableAutoFocus
      >
        <div className="modal-dialog modal-dialog-centered modal-lg">
          <div className="modal-content">
            <div className="justify-content-center d-flex align-items-center mt-1">
              <span style={{ fontSize: '30px' }}>Detail</span>
            </div>
            <div className="modal-body">
              <Formik
                initialValues={{
                  quantity: ''
                }}
                validate={values => {
                  let errors = {}

                  if (!values.quantity)
                    errors.quantity = 'Quantity is required.'

                  return errors
                }}
                onSubmit={handleSubmitApprove}
                render={formProps => {
                  return (
                    <Form className="col">
                      <div ref={componentRef}>
                        <div className="mt-2 ml-2 mr-2">
                          <div className="row mt-1 ml-1 mr-1">
                            <span
                              style={{
                                fontSize: '20px',
                                float: 'left'
                              }}
                            >
                              Name : {openDetailModalData.full_name}
                            </span>
                          </div>
                          <br />
                          <div className="row mt-1 ml-1 mr-1">
                            <span
                              style={{
                                fontSize: '20px',
                                float: 'left'
                              }}
                            >
                              Phone Number : {openDetailModalData.mobile}
                            </span>
                          </div>
                          <br />
                          <div className="row mt-1 ml-1 mr-1">
                            <span
                              style={{
                                fontSize: '20px',
                                float: 'left'
                              }}
                            >
                              Address : {openDetailModalData.delivery}
                            </span>
                          </div>
                        </div>
                      </div>
                      <div className="modal-footer">
                        <button
                          type="button"
                          className="btn btn-secondary"
                          onClick={() => handlePrint()}
                        >
                          Print
                        </button>
                        <button
                          type="button"
                          className="btn btn-secondary"
                          onClick={() => handleClose()}
                        >
                          Close
                        </button>
                      </div>
                    </Form>
                  )
                }}
              />
            </div>
          </div>
        </div>
      </Modal>

      <Modal
        open={openImage}
        disablePortal
        disableEnforceFocus
        disableAutoFocus
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <TransformWrapper
              defaultScale={1}
              defaultPositionX={200}
              defaultPositionY={100}
            >
              {({ zoomIn, zoomOut, resetTransform, ...rest }) => (
                <React.Fragment>
                  <TransformComponent>
                    <div className="modal-body">
                      <img
                        style={{
                          width: '100%',
                          height: '100%',
                          maxWidth: '500px',
                          maxHeight: '500px'
                        }}
                        src={imgSelect}
                      />
                    </div>
                  </TransformComponent>
                  <div className="modal-footer">
                    <div className="tools">
                      <button
                        type="button"
                        className="btn btn-primary mr-1"
                        onClick={zoomIn}
                      >
                        +
                      </button>
                      <button
                        type="button"
                        className="btn btn-primary mr-1"
                        onClick={zoomOut}
                      >
                        -
                      </button>
                    </div>
                    <button
                      type="button"
                      className="btn btn-secondary"
                      onClick={() => handleClose()}
                    >
                      Close
                    </button>
                  </div>
                </React.Fragment>
              )}
            </TransformWrapper>
          </div>
        </div>
      </Modal>

      <Modal open={reject} disablePortal disableEnforceFocus disableAutoFocus>
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="justify-content-center d-flex align-items-center mt-1 ml-1 mr-1 ">
              <span style={{ fontSize: '30px' }}>Change status</span>
            </div>
            <div className="modal-body">
              <Formik
                initialValues={{
                  quantity: ''
                }}
                validate={values => {
                  let errors = {}

                  return errors
                }}
                onSubmit={handleSubmitStatus}
                render={formProps => {
                  return (
                    <Form className="col">
                      <div className="row justify-content-center d-flex align-items-center">
                        <span
                          style={{
                            fontSize: '20px',
                            textAlign: 'center'
                          }}
                        >
                          Do you want to change status to complete ?
                        </span>
                      </div>
                      <div className="modal-footer">
                        <button
                          className="btn btn-primary"
                          type="submit"
                          disabled={formProps.isSubmitting}
                        >
                          Update status
                        </button>
                        <button
                          type="button"
                          className="btn btn-secondary"
                          onClick={() => handleClose()}
                        >
                          Close
                        </button>
                      </div>
                    </Form>
                  )
                }}
              />
            </div>
          </div>
        </div>
      </Modal>
    </Card>
  )
}
