import React, { useState, useEffect } from 'react'
import { Card, CardBody, CardHeader } from '../../_metronic/_partials/controls'
import { useSubheader } from '../../_metronic/layout'
import { baseURL, baseURLDASHBOARD } from '../service/API.js'
import moment from 'moment'
import TextField from '@material-ui/core/TextField'
import Chart from 'react-apexcharts'
import axios from 'axios'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import { makeStyles } from '@material-ui/core/styles'
import { useSelector } from 'react-redux'
const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 3
  },
  paper: {
    padding: theme.spacing(2),
    margin: 'auto',
    marginBottom: '20px',
    marginTop: '20px',
    maxWidth: '500px'
  },
  image: {
    width: 128,
    height: 128
  },
  img: {
    margin: 'auto',
    display: 'block',
    maxWidth: '100%',
    maxHeight: '100%'
  }
}))

export const DashboardPage = () => {
  const { user } = useSelector(state => state.auth)
  const suhbeader = useSubheader()
  var d = new Date()
  d.setDate(d.getDate() - 30)
  const [dateFrom, setDateFrom] = useState(moment(d).format('YYYY-MM-DD'))
  const [dateTo, setDateTo] = useState(moment(new Date()).format('YYYY-MM-DD'))
  const [chart, setChart] = useState([])

  const classes = useStyles()
  suhbeader.setTitle('Dashboard Page')
  useEffect(() => {
    var dataPie = {
      date_from: moment(dateFrom).format('YYYY-MM-DD'),
      date_to: moment(dateTo).format('YYYY-MM-DD')
    }
    getPieChart(dataPie)
  }, [])
  const [barState, setBarState] = useState()
  const [barState2, setBarState2] = useState()
  const [barState3, setBarState3] = useState()
  const [pieState, setPieState] = useState()
  const [pieState2, setPieState2] = useState()
  const getPieChart = data => {
    axios
      .get(
        `${baseURLDASHBOARD}/dashboard/render?dateFrom=${data.date_from}&dateTo=${data.date_to}`
      )
      .then(res => {
        setPieState()
        setBarState()
        var count = 0
        var count_data = []
        if (res.data.length > 0) {
          res.data.map((re_data, i) => {
            if (re_data.type === 'summary') {
              var option = {
                type: 'summary',
                boxBackgroundColor: re_data.boxBackgroundColor,
                boxBackgroundType: re_data.boxBackgroundType,
                boxSize: re_data.boxSize,
                title: re_data.title,
                titleFontSize: re_data.titleFontSize,
                titleFontColor: re_data.titleFontColor,
                dataFontSize: re_data.dataFontSize,
                dataFontColor: re_data.dataFontColor,
                data: re_data.data,
                titleIcon: re_data.titleIcon
              }
              count_data.push(option)
            } else {
              if (re_data.subType === 'pie') {
                var key = []
                var value = []
                re_data.data.map(data => {
                  value.push(data.total)
                  key.push(data.name)
                })

                var option = {
                  series: value,
                  type: 'pie',
                  boxBackgroundColor: re_data.boxBackgroundColor,
                  boxBackgroundType: re_data.boxBackgroundType,
                  boxSize: re_data.boxSize,
                  title: re_data.title,
                  titleFontSize: re_data.titleFontSize,
                  titleFontColor: re_data.titleFontColor,
                  dataFontSize: re_data.dataFontSize,
                  dataFontColor: re_data.dataFontColor,
                  data: re_data.data,
                  options: {
                    title: {
                      text: re_data.title ? re_data.title : null
                    },
                    chart: {
                      toolbar: {
                        show: true,
                        offsetX: 0,
                        offsetY: 0,
                        tools: {
                          download: true
                        },
                        export: {
                          svg: {
                            filename: 'undefined',
                            columnDelimiter: ',',
                            headerCategory: 'category',
                            headerValue: 'value',
                            dateFormatter(timestamp) {
                              return new Date(timestamp).toDateString()
                            }
                          }
                        },
                        autoSelected: 'zoom'
                      },
                      width: 380,
                      type: 'pie'
                    },
                    labels: key,
                    responsive: [
                      {
                        breakpoint: 480,
                        options: {
                          chart: {
                            width: 200
                          },
                          legend: {
                            position: 'bottom'
                          }
                        }
                      }
                    ]
                  }
                }

                count_data.push(option)
              } else if (re_data.subType === 'bar') {
                var item = []

                re_data.data.map(data => {
                  item.push({
                    data: [{ x: data.name, y: data.total }],
                    name: data.name
                  })
                })
                console.log(item)
                var option = {
                  type: 'bar',
                  boxBackgroundColor: re_data.boxBackgroundColor,
                  boxBackgroundType: re_data.boxBackgroundType,
                  boxSize: re_data.boxSize,
                  title: re_data.title,
                  data: re_data.data,
                  titleFontSize: re_data.titleFontSize,
                  titleFontColor: re_data.titleFontColor,
                  dataFontSize: re_data.dataFontSize,
                  dataFontColor: re_data.dataFontColor,
                  series: item,
                  options: {
                    title: {
                      text: re_data.title ? re_data.title : null
                    },
                    chart: {
                      type: 'bar',
                      toolbar: { tools: { download: true } },
                      height: 350
                    },
                    plotOptions: {
                      bar: {
                        horizontal: false
                      }
                    },
                    xaxis: {
                      type: 'category'
                    }
                  }
                }
                count_data.push(option)
              }
            }
          })
        }
        setChart(count_data)
      })
      .catch(err => {
        console.log(err)
      })
  }
  const getBarChart = data => {
    axios
      .get(
        `${baseURL}/dashboard/render?date_from=${data.date_from}&date_to=${data.date_to}`
      )
      .then(res => {
        setBarState()
        if (res.data.length > 0) {
          var item = []

          console.log('re_data', res.data)
          res.data.map(data => {
            item.push({ x: data.text, y: data.total, series: data.group })
          })

          console.log(res.data)
          var option = {
            series: [
              {
                name: item.series,
                data: item
              }
            ],
            options: {
              title: {
                text:
                  res.data.length === 3
                    ? 'Total Transaction By Date'
                    : 'Total Redeem By Date',
                align: 'left',
                margin: 10,
                offsetX: 0,
                offsetY: 0,
                floating: false,
                style: {
                  fontSize: '14px',
                  fontWeight: 'bold',
                  fontFamily: undefined,
                  color: '#263238'
                }
              },
              chart: {
                type: 'bar',
                toolbar: { tools: { download: true } },
                height: 350
              },
              plotOptions: {
                bar: {
                  horizontal: true
                }
              },
              xaxis: {
                type: 'category'
              }
            }
          }
          setBarState(option)
        }
      })
      .catch(err => {
        console.log(err)
      })
  }
  const handleChangeDateFrom = date => {
    setDateFrom(date)
    var dataPie = {
      date_from: moment(date).format('YYYY-MM-DD'),
      date_to: moment(dateTo).format('YYYY-MM-DD')
    }
    getPieChart(dataPie)
    var dataBar = {
      date_from: moment(date).format('YYYY-MM-DD'),
      date_to: moment(dateTo).format('YYYY-MM-DD')
    }
    getBarChart(dataBar)
  }
  const handleChangeDateTo = date => {
    setDateTo(date)
    var dataPie = {
      date_from: moment(dateFrom).format('YYYY-MM-DD'),
      date_to: moment(date).format('YYYY-MM-DD')
    }
    getPieChart(dataPie)
    var dataBar = {
      date_from: moment(dateFrom).format('YYYY-MM-DD'),
      date_to: moment(date).format('YYYY-MM-DD')
    }
    getBarChart(dataBar)
  }
  return (
    <Card>
      <CardHeader title="Dashboard"></CardHeader>
      <CardBody>
        <TextField
          id="DateFrom"
          type="date"
          label="Date From"
          defaultValue={dateFrom}
          className=" mb-3 mr-3"
          style={{
            height: '38px',
            width: '185px',
            backgroundColor: '#F4F6F9',
            borderRadius: '5px',
            borderWidth: '0'
          }}
          InputLabelProps={{
            shrink: true
          }}
          onChange={name => {
            handleChangeDateFrom(name.target.value)
          }}
        />
        <TextField
          id="DateFrom"
          type="date"
          label="Date To"
          defaultValue={dateTo}
          className=" mb-3 mr-3"
          style={{
            height: '38px',
            width: '185px',
            backgroundColor: '#F4F6F9',
            borderRadius: '5px',
            borderWidth: '0'
          }}
          InputLabelProps={{
            shrink: true
          }}
          onChange={name => {
            handleChangeDateTo(name.target.value)
          }}
        />
        <div className="row">
          {chart.map((data, i) => {
            return (
              <>
                {String(data.type) === 'summary' ? (
                  <div className={`${data.boxSize}`}>
                    <Paper
                      className={classes.paper}
                      style={{ backgroundColor: `${data.boxBackgroundColor}` }}
                    >
                      <span
                        style={{
                          fontSize: `${data.titleFontSize}`,
                          color: `${data.dataFontColor}`
                        }}
                      >
                        {data.title}
                      </span>
                      <div class="d-flex justify-content-between">
                        <span
                          style={{
                            fontSize: `${data.dataFontSize}`,
                            color: `${data.dataFontColor}`
                          }}
                        >
                          {data.data}
                        </span>
                        <img
                          src={data.titleIcon}
                          style={{
                            width: '50px',
                            height: '50px',
                            textAlign: 'right'
                          }}
                        />
                      </div>

                      <br />
                    </Paper>
                  </div>
                ) : null}
              </>
            )
          })}
        </div>

        <div className="row">
          {chart.map((data, i) => {
            return (
              <>
                {String(data.type) === 'summary' ? null : (
                  <div className={`${data.boxSize}`}>
                    <Paper className={classes.paper}>
                      <Chart
                        options={data.options}
                        series={data.series}
                        type={`${data.type}`}
                        height={300}
                      />
                    </Paper>
                  </div>
                )}
              </>
            )
          })}
        </div>
      </CardBody>
    </Card>
  )
}
