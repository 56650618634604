import React, { useState, useEffect } from 'react'
import { Card, CardBody, CardHeader } from '../../_metronic/_partials/controls'
import {
  NoRecordsFoundMessage,
  PleaseWaitMessage,
  sortCaret,
  headerSortingClasses
} from '../../_metronic/_helpers'
import BootstrapTable from 'react-bootstrap-table-next'
import TableHeaderColumn from 'react-bootstrap-table-next'
import ToolkitProvider, { CSVExport } from 'react-bootstrap-table2-toolkit'
import Pagination from '@material-ui/lab/Pagination'
import { useSubheader } from '../../_metronic/layout'
import { makeStyles } from '@material-ui/core/styles'
import axios from 'axios'
import moment from 'moment'
import { baseURL, baseURLDASHBOARD } from '../service/API.js'
import TextField from '@material-ui/core/TextField'
import { useSelector } from 'react-redux'
import { tr } from 'date-fns/locale'
import { set } from 'object-path'

const useStyles = makeStyles({
  root: {
    padding: '2px 4px',
    display: 'flex',
    alignItems: 'center',
    width: 400
  },
  input: {
    marginLeft: 8,
    flex: 1
  },
  iconButton: {
    padding: 10
  },
  divider: {
    width: 1,
    height: 28,
    margin: 4
  }
})

export const Report = () => {
  const { user } = useSelector(state => state.auth)
  const suhbeader = useSubheader()

  const [reportData, setReportData] = useState([])
  const [listReport, setListReport] = useState([])
  const [col, setCol] = useState([])
  const [reportValue, setReportValue] = useState(0)
  const [page, setPage] = useState(1)
  const [totalPage, setTotalPage] = useState(1)
  const [sizePerPage, setSizePerPage] = useState(10)
  const [totalSize, setTotalSize] = useState(100)
  const [dateFrom, setDateFrom] = useState(
    moment(new Date()).format('YYYY-MM-DD')
  )
  const [dateTo, setDateTo] = useState(moment(new Date()).format('YYYY-MM-DD'))
  suhbeader.setTitle('Report')

  const { ExportCSVButton } = CSVExport

  useEffect(() => {
    getListReport()
  }, [])

  const getListReport = () => {
    const apiUrls = `${baseURLDASHBOARD}/dashboard/report?page=1&limit=10000`
    const resultAll = axios({
      method: 'get',
      url: apiUrls,
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        'Access-Control-Allow-Origin': '*',
        Authorization: `Bearer ${user.data.access_token}`
      }
    })
      .then(res => {
        setListReport(res.data.data)
      })
      .catch(err => {
        console.log(err)
      })
  }

  const bindReport = (id, _page, _sizePerPage) => {
    setReportValue(id)
    // setReportData([])
    var data = {
      report_id: id,
      date_from: moment(dateFrom).format('YYYY-MM-DD'),
      date_to: moment(dateTo).format('YYYY-MM-DD')
    }
    const apiUrls = `${baseURLDASHBOARD}/dashboard/report/render/${data.report_id}?page=${_page}&limit=${_sizePerPage}&dateFrom=${data.date_from}&dateTo=${data.date_to}`
    const resultAll = axios({
      method: 'get',
      url: apiUrls,
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        'Access-Control-Allow-Origin': '*',
        Authorization: `Bearer ${user.data.access_token}`
      }
    })
      .then(async res => {
        var keys = []
        var newState = []
        keys = Object.keys(res.data.data[0])
        keys.map(async key => {
          newState.push({
            dataField: key,
            text: key,
            sort: true,
            sortCaret: sortCaret,
            headerSortingClasses
          })
        })

        var totalpage = Math.ceil(res.data.total / res.data.limit)
        setTotalPage(totalpage)
        setTotalSize(res.data.total)
        setCol(newState)
        setReportData(res.data.data)
      })
      .catch(err => {
        console.log(err)
      })
  }

  const changeReportDateFrom = (_dateFrom, _page, _sizePerPage) => {
    setDateFrom(_dateFrom)
    var data = {
      report_id: reportValue,
      date_from: moment(_dateFrom).format('YYYY-MM-DD'),
      date_to: moment(dateTo).format('YYYY-MM-DD')
    }

    const apiUrls = `${baseURLDASHBOARD}/dashboard/report/render/${data.report_id}?page=${_page}&limit=${_sizePerPage}&dateFrom=${data.date_from}&dateTo=${data.date_to}`
    const resultAll = axios({
      method: 'get',
      url: apiUrls,
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        'Access-Control-Allow-Origin': '*',
        Authorization: `Bearer ${user.data.access_token}`
      }
    })
      .then(res => {
        var keys = []
        var newState = []
        keys = Object.keys(res.data.data[0])
        keys.map(async key => {
          newState.push({
            dataField: key,
            text: key,
            sort: true,
            sortCaret: sortCaret,
            headerSortingClasses
          })
        })

        var totalpage = Math.ceil(res.data.total / res.data.limit)
        setTotalPage(totalpage)
        setTotalSize(res.data.total)
        setCol(newState)
        setReportData(res.data.data)
      })
      .catch(err => {
        console.log(err)
      })
  }

  const changeReportDateTo = (_dateTo, _page, _sizePerPage) => {
    setDateTo(_dateTo)
    var data = {
      report_id: reportValue,
      date_from: moment(dateFrom).format('YYYY-MM-DD'),
      date_to: moment(_dateTo).format('YYYY-MM-DD')
    }

    const apiUrls = `${baseURLDASHBOARD}/dashboard/report/render/${data.report_id}?page=${_page}&limit=${_sizePerPage}&dateFrom=${data.date_from}&dateTo=${data.date_to}`
    const resultAll = axios({
      method: 'get',
      url: apiUrls,
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        'Access-Control-Allow-Origin': '*',
        Authorization: `Bearer ${user.data.access_token}`
      }
    })
      .then(res => {
        var keys = []
        var newState = []
        keys = Object.keys(res.data.data[0])
        keys.map(async key => {
          newState.push({
            dataField: key,
            text: key,
            sort: true,
            sortCaret: sortCaret,
            headerSortingClasses
          })
        })

        var totalpage = Math.ceil(res.data.total / res.data.limit)
        setTotalPage(totalpage)
        setTotalSize(res.data.total)
        setCol(newState)
        setReportData(res.data.data)
      })
      .catch(err => {
        console.log(err)
      })
  }

  function imageFormatter(cell, row) {
    return (
      <input
        type="image"
        src={cell}
        alt="priv-image"
        width="48"
        height="48"
      ></input>
    )
  }

  const handlePageChange = (event, value) => {
    setPage(value)
    bindReport(reportValue, value, sizePerPage)
  }

  const handlePageSizeChange = event => {
    setSizePerPage(event.target.value)
    setPage(1)
    bindReport(reportValue, 1, event.target.value)
  }

  return (
    <Card>
      <CardHeader title="Report">
        <select
          as="select"
          className="m-3"
          style={{ width: '300px' }}
          defaultValue={0}
          onChange={id => bindReport(id.target.value, 1, sizePerPage)}
        >
          <option disabled value={0}>
            {' '}
            Please Select ...
          </option>
          {listReport.map((data, index) => {
            return (
              <option key={index} value={data.id}>
                {data.title}
              </option>
            )
          })}
        </select>
      </CardHeader>
      <CardBody>
        {Number(reportValue) !== 0 && reportData.length > 0 ? (
          <>
            <ToolkitProvider keyField={reportValue} exportCSV>
              {props => (
                <div>
                  <>
                    <TextField
                      id="DateFrom"
                      type="date"
                      label="Date From"
                      defaultValue={dateFrom}
                      className="mt-3 mb-3 mr-3"
                      style={{
                        height: '38px',
                        width: '185px',
                        backgroundColor: '#F4F6F9',
                        borderRadius: '5px',
                        borderWidth: '0'
                      }}
                      InputLabelProps={{
                        shrink: true
                      }}
                      onChange={name =>
                        changeReportDateFrom(
                          name.target.value,
                          page,
                          sizePerPage
                        )
                      }
                    />
                    <TextField
                      id="DateFrom"
                      type="date"
                      label="Date To"
                      defaultValue={dateTo}
                      className="mt-3 mb-3 mr-3"
                      style={{
                        height: '38px',
                        width: '185px',
                        backgroundColor: '#F4F6F9',
                        borderRadius: '5px',
                        borderWidth: '0'
                      }}
                      InputLabelProps={{
                        shrink: true
                      }}
                      onChange={name =>
                        changeReportDateTo(
                          name.target.value,
                          page,
                          sizePerPage
                        )
                      }
                    />
                  </>
                  <hr />

                  <ExportCSVButton className="mt-3 mb-3" {...props.csvProps}>
                    Export CSV!!
                  </ExportCSVButton>

                  <BootstrapTable
                    {...props.baseProps}
                    data={reportData}
                    columns={col}
                    wrapperClasses="table-responsive"
                    bordered={false}
                    classes="table table-head-custom table-vertical-center overflow-hidden"
                    bootstrap4
                  />

                  <div className=" col-lg-12">
                    <div className="row">
                      <div className="col-lg-6">
                        <div className="mt-3">
                          {'Items per Page: '}
                          <select
                            onChange={handlePageSizeChange}
                            value={sizePerPage}
                          >
                            <option value={10}>10</option>
                            <option value={25}>25</option>
                            <option value={50}>50</option>
                            <option value={100}>100</option>
                          </select>
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <Pagination
                          className="mt-3 "
                          count={totalPage}
                          page={page}
                          siblingCount={1}
                          boundaryCount={1}
                          variant="outlined"
                          shape="rounded"
                          onChange={handlePageChange}
                        />
                      </div>
                    </div>
                  </div>
                  <span className="react-bootstrap-table-pagination-total">
                    Showing {page === 1 ? 1 : sizePerPage * (page - 1) + 1} to{' '}
                    {page === 1
                      ? sizePerPage
                      : page === totalPage
                      ? totalSize
                      : sizePerPage * page}{' '}
                    of {totalSize} Results
                  </span>
                </div>
              )}
            </ToolkitProvider>
          </>
        ) : (
          ''
        )}
      </CardBody>
    </Card>
  )
}
