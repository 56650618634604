import React, { Suspense, lazy } from "react";
import { Redirect, Switch, Route } from "react-router-dom";
import { LayoutSplashScreen, ContentRoute } from "../_metronic/layout";
import { DashboardPage } from "./pages/DashboardPage";
import { Transaction } from "./pages/Transaction";
import { Report } from "./pages/Report";
import { Redemption } from "./pages/Redemption";
export default function BasePage() {
  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <Switch>
        {<Redirect exact from="/" to="/dashboard" />}
        <ContentRoute path="/Dashboard" component={DashboardPage} />

        <ContentRoute path="/Transaction" component={Transaction} />
        <ContentRoute path="/Report" component={Report} />
        <ContentRoute path="/Redemption" component={Redemption} />

        <Redirect to="error/error-v1" />
      </Switch>
    </Suspense>
  );
}
